/* eslint-disable no-console */
/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

/* A tabs module to handle tabs and tab panels display and aria-attributes based. */
(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.tabs = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {
  'use strict';

  /**
   * Deselects all tabs and hides all tabpanels from a given tabs component.
   * @private
   */
  function deselectAllTabs(component) {
    component.querySelectorAll('[role="tab"]').forEach(tab => {
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute('tabindex', '-1');
    });

    component.querySelectorAll('[role="tabpanel"]').forEach(tabPanel => {
      tabPanel.setAttribute('hidden', '');
    });
  }

  /**
   * Selects the panel associated with a given tab via `aria-controls`.
   * @private
   */
  function selectTab(component, tab) {
    const tabPanelId = tab.getAttribute('aria-controls');
    const tabPanel = component.querySelector(`#${tabPanelId}`);

    if (tabPanel === null) {
      console.warn('tabs.js plugin error: Cannot find panel element with id ' + tabPanelId + ' in the flixtabs component:', component);
      return;
    }

    tab.setAttribute('aria-selected', 'true');
    tab.setAttribute('tabindex', '0');
    tabPanel.removeAttribute('hidden');
  }

  /**
   * Handles the tab events.
   * @private
   */
  function attachTabEvents(component, tabs, tab, tabIndex) {
    tab.addEventListener('click', () => {
      deselectAllTabs(component);
      selectTab(component, tab);
    });

    // Handles focus management when Arrow keys are used for navigation
    tab.addEventListener('keydown', ({ key }) => {
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        var lastTabIndex = tabs.length - 1;

        if (key === 'ArrowRight') {
          // if is currently last tab, then next tab is first tab
          var nextTabIndex = (tabIndex + 1) > lastTabIndex ? 0 : tabIndex + 1;
          var nextTab = tabs.item(nextTabIndex);
          nextTab.focus();
        } else if (key === 'ArrowLeft') {
          // if is currently first tab, then prev tab is last tab
          var prevTabIndex = (tabIndex - 1) < 0 ? lastTabIndex : tabIndex - 1;
          var prevTab = tabs.item(prevTabIndex);
          prevTab.focus();
        }
      }
    });

    // if the target is not a button (not recommended)
    // attach keyboard Enter and Space listeners to mimic the
    // "click" native listeners from buttons
    if (tab.tagName !== 'BUTTON') {
      tab.addEventListener('keydown', (event) => {
        if (event.key === ' ' || event.key === 'Enter') {
          deselectAllTabs(component);
          selectTab(component, tab);
        }
      });
    }
  }

  /**
   * Sets basic accessibility attributes to connect the tabs to their panels, provide proper keyboard
   * focus management and enable assistive technologies to announce them as such.
   * @private
   */
  function setupBaseA11yAttributes(component, tab) {
    const tabId = tab.getAttribute('id');
    const isTabSelected = tab.getAttribute('aria-selected') === 'true';
    const tabPanelId = tab.getAttribute('aria-controls');
    const tabPanel = component.querySelector(`#${tabPanelId}`);

    if (tabPanel === null) {
      console.warn('tabs.js plugin error: Cannot find panel element with id ' + tabPanelId + ' in the flixtabs component:', component);
      return;
    }

    tab.setAttribute('role', 'tab');
    tabPanel.setAttribute('role', 'tabpanel');
    tabPanel.setAttribute('aria-labelledby', tabId);

    // Non selected tabs should be off the tab order, they can be focused with the arrow buttons
    if (isTabSelected) {
      tab.setAttribute('tabindex', '0');
      tabPanel.removeAttribute('hidden');
    } else {
      tab.setAttribute('tabindex', '-1');
      tabPanel.setAttribute('hidden', '');
    }
  }

  /**
   * Initializes the plugin.
   */
  var tabs = {
    init() {
      document.querySelectorAll('[data-flixtabs]').forEach(function (target) {
        const tabs = target.querySelectorAll('[aria-controls]');
        const navHorizontalItems = target.querySelector('.flix-nav-horizontal__items');
        const navHorizontalListItems = target.querySelectorAll('.flix-nav-horizontal__item');

        if (tabs.length <= 0) {
          console.warn('tabs.js plugin error: Cannot find tabs with `aria-controls="id"` in the flixtabs component:', target);
          return;
        }

        // When using nav-horizontal, sets basic roles to known elements.
        if (navHorizontalItems) {
          navHorizontalItems.setAttribute('role', 'tablist');
        }

        if (navHorizontalListItems.length > 0) {
          navHorizontalListItems.forEach((listItem) => {
            listItem.setAttribute('role', 'presentation');
          });
        }

        // Sets up each tab and respective panel
        tabs.forEach((tab, index) => {
          setupBaseA11yAttributes(target, tab);
          attachTabEvents(target, tabs, tab, index);
        });
      });
    }
  };

  return tabs;
});
